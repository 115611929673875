/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

// React router
import { useHistory } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Progress,
  Container,
  Row,
  Col,
} from "reactstrap";

// Authentication
import { auth, firestore } from "./../../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

// Auth actions
import {
  INITIALIZE_PROFILE
} from 'store/actions';


// Section Imports
import Loading from './Loading';
import Home from './Home';

function Profile({ profile, dispatch }) {
  // States
  const [user, loading, error] = useAuthState(auth);

  // Navigation
  const history = useHistory();

  // Get profile
  useEffect(() => {
    if (loading) return;
    if (user) {
      console.log("User Loaded: ", user);

      // Load profile
      loadProfile();
    } else {
      // Send back to login
      history.push("/auth/login");
    }
  }, [user, loading]);

  const loadProfile = async () => {
    const profilesRef = firestore.collection("profiles");
    const profile = await profilesRef.doc(user.uid).get();
    if(profile.exists) {
      // Parse profile
      const profileData = profile.data();
      console.log("Profile Exists: ", profileData);

      // Initialize profile
      dispatch({
        type: INITIALIZE_PROFILE,
        payload: profileData
      });
    } else {
      alert("ERROR: Profile does not exist.");
    }
  }

  // Wait for initialization
  if(profile.initialized) {
    // Load home
    return <Home />;
  } else {
    return <Loading />;
  }
}

// Connect to store
const ConnectedProfile = connect(state => ({
  profile: state.profile
}))(Profile);

export default ConnectedProfile;
