/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactDOM from "react-dom";

// 3rd party
import axios from 'axios';
import moment from 'moment';

// Copy to Clipboard
import copy from 'copy-to-clipboard';

// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Progress,
  Container,
  Row,
  Col,
  Modal
} from "reactstrap";

// Config
import config from "config";

// Firebase Firestore
import { auth, firestore } from "./../../../../firebase";

// core components
import ReportingHeader from "components/Headers/ReportingHeader.js";

import {
  SET_REPORTING_SNAPSHOT,
  UPDATE_REPORTING_STAGE
} from 'store/actions';

// Generate query
function generateQuery(query, startDate, endDate) {
  // Queries
  const queries = {
    'trip_activity': `
SELECT *
FROM \`${config.etl.bigquery.project}.${config.etl.bigquery.dataset}.gst_pdb_trip_day_dtl_bidl\`
WHERE d_date >= "${startDate}"
AND d_date <= "${endDate}"
    `,
    'offers_sent': `
SELECT *
FROM \`${config.etl.bigquery.project}.${config.etl.bigquery.dataset}.gst_offer_sent_all\`
WHERE d_send_date >= "${startDate}"
AND d_send_date <= "${endDate}"
    `,
    'offers_redeemed': `
SELECT *
FROM \`${config.etl.bigquery.project}.${config.etl.bigquery.dataset}.DOT_Redeemed_Offers\`
WHERE d_offer_status_dt >= "${startDate}"
AND d_offer_status_dt <= "${endDate}"
    `,
    'tier_credits': `
SELECT *
FROM \`${config.etl.bigquery.project}.${config.etl.bigquery.dataset}.DOT_Gst_Credits_Day\`
WHERE d_gaming_date >= "${startDate}"
AND d_gaming_date <= "${endDate}"
    `
  };

  // Return query
  return queries[query];
}

// Generate file name
function generateFileName(query, startDate, endDate) {
  // Lookup
  const fileNames = {
    'trip_activity': `week_trip_activity_${startDate.replaceAll("-","_")}_to_${endDate.replaceAll("-","_")}.csv`,
    'offers_sent': `week__offers_sent_${startDate.replaceAll("-","_")}_to_${endDate.replaceAll("-","_")}.csv`,
    'offers_redeemed': `week__offers_redeemed_${startDate.replaceAll("-","_")}_to_${endDate.replaceAll("-","_")}.csv`,
    'tier_credits': `week__tier_credits_${startDate.replaceAll("-","_")}_to_${endDate.replaceAll("-","_")}.csv`
  };

  // Return query
  return fileNames[query];
}

// Upload steps
let initialUploadSteps = {
  'trip_activity': false,
  'offers_sent': false,
  'offers_redeemed': false,
  'tier_credits': false,
};

// Create secure Axios
const secureAxios = axios.create();
secureAxios.interceptors.request.use(async (config) => {
    const token = await auth.currentUser?.getIdToken()
    const c = config
    c.headers.Authorization = `Bearer ${token}`
    return c
});

function Upload({ profile, etl, reporting, dispatch }) {
  console.log("Current Reporting Snapshot: ", reporting.snapshot);

  // Get history
  const history = useHistory();

  // States
  const [offerId, setOfferId] = React.useState((etl.job) ? etl.job.offer.id : "");
  const [date, setDate] = React.useState((etl.job) ? etl.job.date : null);
  const [priorDate, setPriorDate] = React.useState((etl.job) ? etl.job.priorDate : null);
  const [pullDate, setPullDate] = React.useState((etl.job) ? etl.job.pullDate : null);

  // States
  const [reportingPeriod, setReportingPeriod] = React.useState((reporting.snapshot) ? reporting.snapshot.startDate : null);
  const [startDate, setStartDate] = React.useState((reporting.snapshot) ? reporting.snapshot.startDate : null);
  const [endDate, setEndDate] = React.useState((reporting.snapshot) ? reporting.snapshot.endDate : null);
  const [query, setQuery] = React.useState("trip_activity");

  // Clear prior job data
  initialUploadSteps['trip_activity'] = (reporting.snapshot && reporting.snapshot.feeds['trip_activity']) ? true : false;
  initialUploadSteps['offers_sent'] = (reporting.snapshot && reporting.snapshot.feeds['offers_sent']) ? true : false;
  initialUploadSteps['offers_redeemed'] = (reporting.snapshot && reporting.snapshot.feeds['offers_redeemed']) ? true : false;
  initialUploadSteps['tier_credits'] = (reporting.snapshot && reporting.snapshot.feeds['tier_credits']) ? true : false;

  // Process steps
  const [uploadSteps, setUploadSteps] = React.useState(initialUploadSteps);

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const uploadFile = event => {
    hiddenFileInput.current.click();
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleFile = async (event) => {
    event.preventDefault();

    // Grab uploaded file
    const fileUploaded = event.target.files[0];
    console.log("Upload File: ", fileUploaded);

    // Check for etl job
    if(!reporting.snapshot) {
      await loadReportingSnapshot();
    }

    // Generate
    const fileName = generateFileName(query, startDate, endDate);

    //props.handleFile(fileUploaded);
    const formData = new FormData();
    formData.append("data", fileUploaded, fileName);
    secureAxios.post(`https://dls-platform-dev.uc.r.appspot.com/api/v1/etl/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    }).then((response) => {
      console.log("Uploaded: ", response);

      // If response ok - update UI
      if(response.status == 200) {
        // Update feed status
        if(reporting.snapshot.feeds.hasOwnProperty(query)) {
          // Set to true
          let reportingSnapshotData = reporting.snapshot;
          reportingSnapshotData.feeds[query] = true;

          // Update snapshot
          dispatch({
            type: SET_REPORTING_SNAPSHOT,
            payload: reportingSnapshotData
          });
        }
      }
    }, (error) => {
      console.log(error);
    });
  };

  const updateReportingPeriod = async (evt) => {
    console.log(evt.target.value);

    // Set reporting period
    let parsedReportingPeriod = evt.target.value;

    // Parse startDate
    let parsedStartDate = parsedReportingPeriod.split(" to ")[0];
    console.log("Start Date: ", parsedStartDate);
    let parsedEndDate = parsedReportingPeriod.split(" to ")[1];
    console.log("Start Date: ", parsedEndDate);

    // Check if etl job exists
    const reportingSnapshotRef = firestore.collection("reporting");
    const reportingSnapshot = await reportingSnapshotRef.doc(`${config.client.id}_${parsedStartDate}_${parsedEndDate}`.replaceAll("-","_").replaceAll(" ","_")).get();

    // Check if ETL job exists for given day - if not create
    if(reportingSnapshot.exists) {
      // Notification
      notify("danger", "That reporting period has already been processed. Please select it from the processed menu to modify.");
    } else {
      // Set date
      setReportingPeriod(parsedReportingPeriod);
      setStartDate(parsedStartDate);
      setEndDate(parsedEndDate);
    }

  }

  // Notification
  const [alert, setalert] = React.useState(false);
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Profile Updated
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-check-bold",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  // Load Reporting Snapshot
  const loadReportingSnapshot = async () => {
    console.log("Loading Reporting snapshot...", reportingPeriod, startDate, endDate);

    if(profile.initialized) {
      // Find Reporting snapshot
      const reportingSnapshotRef = firestore.collection("reporting");
      const reportingSnapshot = await reportingSnapshotRef.doc(`${config.client.id}_${startDate}_${endDate}`.replaceAll("-","_").replaceAll(" ","_")).get();

      let reportingSnapshotData;

      // Check if ETL job exists for given day - if not create
      if(!reportingSnapshot.exists) {
        // Create new
        reportingSnapshotData = {
          reportingPeriod: reportingPeriod,
          startDate: startDate,
          endDate: endDate,
          status: "Complete",
          user: {
            id: profile.user.uid,
            name: profile.user.name,
            email: profile.user.email
          },
          feeds: initialUploadSteps
        };

        // Create new profile with authentication
        await reportingSnapshotRef.doc(`${config.client.id}_${startDate}_${endDate}`.replaceAll("-","_")).set(reportingSnapshotData);
      }

      // Track snapshot updates
      firestore.collection("reporting").doc(`${config.client.id}_${startDate}_${endDate}`.replaceAll("-","_"))
        .onSnapshot((doc) => {
          // Parse data
          reportingSnapshotData = doc.data();
          console.log("Updated Reporting Snapshot: ", reportingSnapshotData);

          // Update local store
          dispatch({
            type: SET_REPORTING_SNAPSHOT,
            payload: reportingSnapshotData
          });
        });
    }
  }

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <ReportingHeader
        stage="upload"
        title="Upload Data"
        description="This interface enables you to easily generate the necessary data queries for export and upload them for daily processing."
      />
      <Container id="profile" className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Data Export Queries</h3>
                  </Col>
                  <Col xs="4">

                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="process-offer-id-input"
                      md="2"
                    >
                      Select Reporting Period
                    </Label>
                    <Col md="4">

                      <Input
                        id="process-reporting-period-input"
                        type="select"
                        onChange={updateReportingPeriod}
                        disabled={reporting.snapshot}
                      >
                        <option disabled selected value> -- select an reporting period -- </option>
                        <option selected={reporting.snapshot && reporting.snapshot.reportingPeriod == "2022-11-21 to 2022-11-27"}>2022-11-21 to 2022-11-27</option>
                        <option selected={reporting.snapshot && reporting.snapshot.reportingPeriod == "2022-11-14 to 2022-11-20"}>2022-11-14 to 2022-11-20</option>
                        <option selected={reporting.snapshot && reporting.snapshot.reportingPeriod == "2022-11-07 to 2022-11-13"}>2022-11-07 to 2022-11-13</option>
                        <option selected={reporting.snapshot && reporting.snapshot.reportingPeriod == "2022-10-31 to 2022-11-06"}>2022-10-31 to 2022-11-06</option>
                      </Input>
                    </Col>
                    <Label
                      className="form-control-label"
                      htmlFor="process-offer-id-input"
                      md="1"
                    >
                      Start Date
                    </Label>
                    <Col md="2">
                      <Input
                        defaultValue={startDate}
                        id="process-date-input"
                        type="text"
                        disabled
                      />
                    </Col>
                    <Label
                      className="form-control-label"
                      htmlFor="process-offer-id-input"
                      md="1"
                    >
                      End Date
                    </Label>
                    <Col md="2">
                      <Input
                        defaultValue={endDate}
                        id="process-date-input"
                        type="text"
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Col md="6">
                      <ListGroup>
                        <ListGroupItem
                          className={`list-group-item-action flex-column align-items-start py-4 px-4${(uploadSteps.trip_activity) ? ' bg-success' : ''}`}
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            setQuery("trip_activity");
                          }}
                          active={query == "trip_activity"}
                        >
                          <p className="text-sm mb-0">
                            <span className="text-info bold">Trip Activity</span> – This query extracts the full details for all customer trip activity for our target date.
                          </p>
                        </ListGroupItem>
                        <ListGroupItem
                          className={`list-group-item-action flex-column align-items-start py-4 px-4${(uploadSteps.offers_sent) ? ' bg-success' : ''}`}
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            setQuery("offers_sent");
                          }}
                          active={query == "offers_sent"}
                        >
                          <p className="text-sm mb-0">
                            <span className="text-info bold">Offers Sent</span> – This query extracts the full details for all offers sent for relevant prior dates.
                          </p>
                        </ListGroupItem>
                        <ListGroupItem
                          className={`list-group-item-action flex-column align-items-start py-4 px-4${(uploadSteps.offers_redeemed) ? ' bg-success' : ''}`}
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            setQuery("offers_redeemed");
                          }}
                          active={query == "offers_redeemed"}
                        >
                          <p className="text-sm mb-0">
                            <span className="text-info bold">Offers Redeemed</span> – This query extracts the full details for customer offer redemption behavior for prior dates.
                          </p>
                        </ListGroupItem>
                        <ListGroupItem
                          className={`list-group-item-action flex-column align-items-start py-4 px-4${(uploadSteps.tier_credits) ? ' bg-success' : ''}`}
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            setQuery("tier_credits");
                          }}
                          active={query == "tier_credits"}
                        >
                          <p className="text-sm mb-0">
                            <span className="text-info bold">Tier Credits</span> – This query extracts the full details of earned tier credits by guests for prior dates.
                          </p>
                        </ListGroupItem>
                      </ListGroup>
                    </Col>
                    <Col md="6">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <Input
                              id="query-editor"
                              resize="none"
                              rows="14"
                              type="textarea"
                              value={generateQuery(query, startDate, endDate)}
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <Button block color="default" size="lg" onClick={() => {
                            window.open("https://console.cloud.google.com/bigquery", '_blank').focus();
                          }}>
                              <span className="btn-inner--icon mr-2">
                                <i className="ni ni-app" />
                              </span>
                              <span className="btn-inner--text">Open BigQuery</span>
                          </Button>
                        </Col>
                        <Col md="4">
                          <Button block color="primary" size="lg" onClick={() => {
                            // Copy query
                            copy(generateQuery(query, startDate, endDate));
                          }}>
                              <span className="btn-inner--icon mr-2">
                                <i className="ni ni-ungroup" />
                              </span>
                              <span className="btn-inner--text">Copy Query to Clipboard</span>
                          </Button>
                        </Col>
                        <Col md="4">
                          { !uploadSteps[query] &&
                            <Button block color="secondary" size="lg" onClick={uploadFile}>
                                <span className="btn-inner--icon mr-2">
                                  <i className="ni ni-cloud-upload-96" />
                                </span>
                                <span className="btn-inner--text">Upload Query Results</span>
                                <input
                                  type="file"
                                  ref={hiddenFileInput}
                                  onChange={handleFile}
                                  style={{display: 'none'}}
                                />
                            </Button>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                  <hr className="my-4" />
                  <Row>
                    <Col md="4">
                      <div style={{
                        height: '100%',
                        float: 'left'
                      }}>
                        <Button
                          color="primary"
                          href=""
                          size="xl"
                          onClick={() => {
                            // Go to sent
                            history.push("/admin/offers/sent");
                          }}
                        >
                          View Processed Offers
                        </Button>
                      </div>
                    </Col>
                    <Col className="text-right" md="8">
                      { (etl.job && etl.job.status == "Complete") &&
                        <div style={{
                          height: '100%',
                          float: 'right'
                        }}>
                          <Button
                            color="secondary"
                            href=""
                            size="xl"
                            onClick={() => {
                              dispatch({
                                type: UPDATE_REPORTING_STAGE,
                                payload: {
                                  reportingPeriod: reportingPeriod,
                                  startDate: startDate,
                                  endDate: endDate,
                                  stage: 'review'
                                }
                              });
                            }}
                          >
                            Review
                          </Button>
                        </div>
                      }
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedUpload = connect(state => ({
  profile: state.profile,
  etl: state.etl,
  reporting: state.reporting
}))(Upload);

export default ConnectedUpload;
