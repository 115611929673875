/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactDOM from "react-dom";

// 3rd party
import axios from 'axios';
import moment from 'moment';

// Copy to Clipboard
import copy from 'copy-to-clipboard';

// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Progress,
  Container,
  Row,
  Col,
  Modal
} from "reactstrap";

// Config
import config from "config";

// Firebase Firestore
import { firestore } from "./../../../../firebase";

// core components
import OffersHeader from "components/Headers/OffersHeader.js";

import {
  SET_ETL_JOB,
  UPDATE_PROCESS_STAGE
} from 'store/actions';

// Generate query
function generateQuery(query, date, priorDate) {
  // Queries
  const queries = {
    'rated_day': `
WITH
  tier_credits as (
    SELECT *
    FROM \`${config.etl.bigquery.project}.${config.etl.bigquery.dataset}.DOT_Gst_Credits_Day\`
    WHERE d_gaming_date = "${date}"
  ),
  mailable_guests as (
    SELECT i_dmid, c_mailable, c_credit_status_flag, c_prop_mail_cd
    FROM \`${config.etl.bigquery.project}.${config.etl.bigquery.dataset}.marketing_campaigns\`
  ),
  rated_days as (
    SELECT
      CAST(rd.i_dmid as int) as i_dmid,
      rd.d_date,
      IFNULL(rd.f_theo_slot, 0.0) as f_theo_slot,
      IFNULL(rd.f_act_slot, 0.0) as f_act_slot,
      IFNULL(rd.f_act_slot * 0.4, 0.0) as f_actual_win_40pct_sum,
      IFNULL(tc.f_tc_slot_game_earn, 0.0) as f_tc_slot_game_earn,
      IFNULL(g.c_mailable, "X") as c_mailable,
      IFNULL(g.c_credit_status_flag, "X") as c_credit_status_flag,
      IFNULL(g.c_prop_mail_cd, "X") as c_prop_mail_cd
    FROM \`${config.etl.bigquery.project}.${config.etl.bigquery.dataset}.gst_pdb_trip_day_dtl_bidl\` rd
    LEFT JOIN mailable_guests g ON rd.i_dmid = g.i_dmid
    LEFT JOIN tier_credits tc ON rd.i_dmid = tc.i_dmid
    WHERE d_date = "${date}"
    AND (rd.f_theo_slot > 0.0 OR rd.f_act_slot > 0.0)
    AND tc.f_tc_slot_game_earn >= 200
  )

SELECT *
FROM rated_days
WHERE c_mailable != "N"
AND c_credit_status_flag != 'Y'
    `,
    'trip_activity': `
SELECT *
FROM \`${config.etl.bigquery.project}.${config.etl.bigquery.dataset}.gst_pdb_trip_day_dtl_bidl\`
WHERE d_date = "${date}"
    `,
    'offers_sent': `
SELECT *
FROM \`${config.etl.bigquery.project}.${config.etl.bigquery.dataset}.gst_offer_sent_all\`
WHERE d_send_date = "${priorDate}"
    `,
    'offers_redeemed': `
SELECT *
FROM \`${config.etl.bigquery.project}.${config.etl.bigquery.dataset}.DOT_Redeemed_Offers\`
WHERE d_offer_status_dt = "${date}"
    `,
    'tier_credits': `
SELECT *
FROM \`${config.etl.bigquery.project}.${config.etl.bigquery.dataset}.DOT_Gst_Credits_Day\`
WHERE d_gaming_date = "${date}"
    `
  };

  // Return query
  return queries[query];
}

// Generate file name
function generateFileName(query, date, offerId) {
  // Generate file parts
  const dateParts = date.split("-");

  // Lookup
  const fileNames = {
    'rated_day': `${offerId}_rated_day_${dateParts[0]}_${dateParts[1]}_${dateParts[2]}.csv`,
    'trip_activity': `${offerId}_trip_activity_${dateParts[0]}_${dateParts[1]}_${dateParts[2]}.csv`,
    'offers_sent': `${offerId}_offers_sent_${dateParts[0]}_${dateParts[1]}_${dateParts[2]}.csv`,
    'offers_redeemed': `${offerId}_offers_redeemed_${dateParts[0]}_${dateParts[1]}_${dateParts[2]}.csv`,
    'tier_credits': `${offerId}_tier_credits_${dateParts[0]}_${dateParts[1]}_${dateParts[2]}.csv`
  };

  // Return query
  return fileNames[query];
}

// Upload steps
let initialUploadSteps = {
  'trip_activity': false,
  'offers_sent': false,
  'offers_redeemed': false,
  'rated_day': false,
  'tier_credits': false,
};

function Upload({ profile, etl, dispatch }) {
  console.log("Current ETL Job: ", etl);

  // Get history
  const history = useHistory();

  // States
  const [offerId, setOfferId] = React.useState((etl.job) ? etl.job.offer.id : "");
  // const [date, setDate] = React.useState((etl.job) ? etl.job.date : new Date().toISOString().split('T')[0]);
  const [date, setDate] = React.useState((etl.job) ? etl.job.date : null);
  const [priorDate, setPriorDate] = React.useState((etl.job) ? etl.job.priorDate : null);
  const [pullDate, setPullDate] = React.useState((etl.job) ? etl.job.pullDate : null);
  const [query, setQuery] = React.useState("rated_day");

  // Clear prior job data
  initialUploadSteps['trip_activity'] = (etl.job && etl.job.feeds['trip_activity']) ? true : false;
  initialUploadSteps['offers_sent'] = (etl.job && etl.job.feeds['offers_sent']) ? true : false;
  initialUploadSteps['offers_redeemed'] = (etl.job && etl.job.feeds['offers_redeemed']) ? true : false;
  initialUploadSteps['rated_day'] = (etl.job && etl.job.feeds['rated_day']) ? true : false;
  initialUploadSteps['tier_credits'] = (etl.job && etl.job.feeds['tier_credits']) ? true : false;

  // Process steps
  const [uploadSteps, setUploadSteps] = React.useState(initialUploadSteps);

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const uploadFile = event => {
    hiddenFileInput.current.click();
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleFile = async (event) => {
    event.preventDefault();

    // Check for valid Offer ID
    if(offerId.length !== 5 || (offerId.toUpperCase() !== offerId)) {
      // Notification
      notify("danger", "Invalid Offer ID - must be exactly 5 characters and only uppercase or numbers.");
      return;
    }

    // Grab uploaded file
    const fileUploaded = event.target.files[0];
    console.log("Upload File: ", fileUploaded);

    // Check for etl job
    if(!etl.job) {
      await loadETLJob();
    }

    // Generate
    const fileName = generateFileName(query, date, offerId);

    //props.handleFile(fileUploaded);
    const formData = new FormData();
    formData.append("data", fileUploaded, fileName);
    axios.post(`${config.etl.api.url}/caesars-southern-indiana/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    });

    // Set query as complete
    let updatedUploadSteps = uploadSteps;
    updatedUploadSteps[query] = true;
    setUploadSteps(updatedUploadSteps);
  };

  const updateDate = async (evt) => {
    // Parse date
    let newDate = evt.target.value;

    // Parse date and set 2 days back
    let parsedDate = moment(newDate, 'YYYY-MM-DD');
    console.log("New Date: ", parsedDate);
    let priorDate = parsedDate.subtract(2, 'd').format('YYYY-MM-DD');
    console.log("2 Days Prior: ", priorDate);
    let pullDate = new Date().toISOString().split('T')[0];

    // Check if etl job exists
    const etlJobRef = firestore.collection("etl");
    const etlJob = await etlJobRef.doc(`${config.client.id}_${newDate}`.replaceAll("-","_")).get();

    // Check if ETL job exists for given day - if not create
    if(etlJob.exists) {
      // Notification
      notify("danger", "That day has already been processed. Please select it from the processed menu to modify.");
    } else {
      // Set date
      setDate(newDate);
      setPriorDate(priorDate);
      setPullDate(pullDate);
    }

  }

  // Notification
  const [alert, setalert] = React.useState(false);
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Profile Updated
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-check-bold",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  // Load ETL process job
  const loadETLJob = async () => {
    console.log("Loading ETL Job...", date, offerId);

    if(profile.initialized) {
      // Find ETL job
      const etlJobRef = firestore.collection("etl");
      const etlJob = await etlJobRef.doc(`${config.client.id}_${date}`.replaceAll("-","_")).get();

      let etlJobData;

      // Check if ETL job exists for given day - if not create
      if(!etlJob.exists) {
        // Create new
        etlJobData = {
          date: date,
          pullDate: pullDate,
          priorDate: priorDate,
          status: "Uploading",
          summary: {
            total_recipients: 0,
            total_exposure: 0.0
          },
          user: {
            id: profile.user.uid,
            name: profile.user.name,
            email: profile.user.email
          },
          offer: {
            id: offerId,
          },
          feeds: initialUploadSteps,
          stats: []
        };

        // Create new profile with authentication
        await etlJobRef.doc(`${config.client.id}_${date}`.replaceAll("-","_")).set(etlJobData);
      }

      // Track snapshot updates
      firestore.collection("etl").doc(`${config.client.id}_${date}`.replaceAll("-","_"))
        .onSnapshot((doc) => {
          // Parse data
          etlJobData = doc.data();
          console.log("Updated ETL Job: ", etlJobData);

          // Update local store
          dispatch({
            type: SET_ETL_JOB,
            payload: etlJobData
          });
        });
    }
  }

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <OffersHeader
        stage="upload"
        title="Upload Data"
        description="This interface enables you to easily generate the necessary data queries for export and upload them for daily processing."
      />
      <Container id="profile" className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Data Export Queries</h3>
                  </Col>
                  <Col xs="4">

                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="process-offer-id-input"
                      md="2"
                    >
                      Enter Offer ID
                    </Label>
                    <Col md="5">
                      <Input
                        defaultValue={offerId}
                        onChange={(evt) => {
                          // Set offer ID
                          setOfferId(evt.target.value);
                        }}
                        id="process-date-input"
                        type="text"
                        disabled={etl.job}
                      />
                    </Col>
                    <Label
                      className="form-control-label"
                      htmlFor="process-offer-id-input"
                      md="2"
                    >
                      Select Date
                    </Label>
                    <Col md="3">
                      <Input
                        defaultValue={date}
                        onChange={updateDate}
                        id="process-date-input"
                        type="date"
                        disabled={etl.job}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Col md="6">
                      <ListGroup>
                        <ListGroupItem
                          className={`list-group-item-action flex-column align-items-start py-4 px-4${(uploadSteps.rated_day) ? ' bg-success' : ''}`}
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            setQuery("rated_day");
                          }}
                          active={query == "rated_day"}
                        >
                          <p className="text-sm mb-0">
                            <span className="text-info bold">Rated Day</span> – This query merges daily player activity with the required Tier Credit and mailable qualifications.
                          </p>
                        </ListGroupItem>
                        <ListGroupItem
                          className={`list-group-item-action flex-column align-items-start py-4 px-4${(uploadSteps.trip_activity) ? ' bg-success' : ''}`}
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            setQuery("trip_activity");
                          }}
                          active={query == "trip_activity"}
                        >
                          <p className="text-sm mb-0">
                            <span className="text-info bold">Trip Activity</span> – This query extracts the full details for all customer trip activity for our target date.
                          </p>
                        </ListGroupItem>
                        <ListGroupItem
                          className={`list-group-item-action flex-column align-items-start py-4 px-4${(uploadSteps.offers_sent) ? ' bg-success' : ''}`}
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            setQuery("offers_sent");
                          }}
                          active={query == "offers_sent"}
                        >
                          <p className="text-sm mb-0">
                            <span className="text-info bold">Offers Sent</span> – This query extracts the full details for all offers sent for relevant prior dates.
                          </p>
                        </ListGroupItem>
                        <ListGroupItem
                          className={`list-group-item-action flex-column align-items-start py-4 px-4${(uploadSteps.offers_redeemed) ? ' bg-success' : ''}`}
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            setQuery("offers_redeemed");
                          }}
                          active={query == "offers_redeemed"}
                        >
                          <p className="text-sm mb-0">
                            <span className="text-info bold">Offers Redeemed</span> – This query extracts the full details for customer offer redemption behavior for prior dates.
                          </p>
                        </ListGroupItem>
                        <ListGroupItem
                          className={`list-group-item-action flex-column align-items-start py-4 px-4${(uploadSteps.tier_credits) ? ' bg-success' : ''}`}
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            setQuery("tier_credits");
                          }}
                          active={query == "tier_credits"}
                        >
                          <p className="text-sm mb-0">
                            <span className="text-info bold">Tier Credits</span> – This query extracts the full details of earned tier credits by guests for prior dates.
                          </p>
                        </ListGroupItem>
                      </ListGroup>
                    </Col>
                    <Col md="6">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <Input
                              id="query-editor"
                              resize="none"
                              rows="14"
                              type="textarea"
                              value={generateQuery(query, date, priorDate)}
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <Button block color="default" size="lg" onClick={() => {
                            window.open("https://console.cloud.google.com/bigquery", '_blank').focus();
                          }}>
                              <span className="btn-inner--icon mr-2">
                                <i className="ni ni-app" />
                              </span>
                              <span className="btn-inner--text">Open BigQuery</span>
                          </Button>
                        </Col>
                        <Col md="4">
                          <Button block color="primary" size="lg" onClick={() => {
                            // Copy query
                            copy(generateQuery(query, date, priorDate));
                          }}>
                              <span className="btn-inner--icon mr-2">
                                <i className="ni ni-ungroup" />
                              </span>
                              <span className="btn-inner--text">Copy Query to Clipboard</span>
                          </Button>
                        </Col>
                        <Col md="4">
                          { !uploadSteps[query] &&
                            <Button block color="secondary" size="lg" onClick={uploadFile}>
                                <span className="btn-inner--icon mr-2">
                                  <i className="ni ni-cloud-upload-96" />
                                </span>
                                <span className="btn-inner--text">Upload Query Results</span>
                                <input
                                  type="file"
                                  ref={hiddenFileInput}
                                  onChange={handleFile}
                                  style={{display: 'none'}}
                                />
                            </Button>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                  <hr className="my-4" />
                  <Row>
                    <Col md="4">
                      <div style={{
                        height: '100%',
                        float: 'left'
                      }}>
                        <Button
                          color="primary"
                          href=""
                          size="xl"
                          onClick={() => {
                            // Go to sent
                            history.push("/admin/offers/sent");
                          }}
                        >
                          View Processed Offers
                        </Button>
                      </div>
                    </Col>
                    <Col className="text-right" md="8">
                      { (etl.job && etl.job.status == "Complete") &&
                        <div style={{
                          height: '100%',
                          float: 'right'
                        }}>
                          <Button
                            color="secondary"
                            href=""
                            size="xl"
                            onClick={() => {
                              dispatch({
                                type: UPDATE_PROCESS_STAGE,
                                payload: {
                                  offerId: offerId,
                                  date: date,
                                  stage: 'review'
                                }
                              });
                            }}
                          >
                            Review
                          </Button>
                        </div>
                      }
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedUpload = connect(state => ({
  profile: state.profile,
  etl: state.etl
}))(Upload);

export default ConnectedUpload;
