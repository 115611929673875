/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import ReactDOM from "react-dom";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Progress,
  Container,
  Row,
  Col,
  Modal,
  Media,
  Table
} from "reactstrap";

// core components
import OffersHeader from "components/Headers/OffersHeader.js";

import {
  UPDATE_PROCESS_STAGE
} from 'store/actions';

function Review({ profile, etl, dispatch }) {
  // States
  const listRef = React.useRef(null);
  const [report, setReport] = React.useState("total_counts_exposure");
  const [statindex, setStatindex] = React.useState(0);

  // Get profile (use effect)
  // useEffect(() => {
  //   // syncProfile();
  // }, []);

  return (
    <>
      <OffersHeader
        stage="review"
        title="Review Offers"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      />
      <Container id="profile" className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Review Processed Offers</h3>
                  </Col>
                  <Col xs="4">

                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <FormGroup className="row">
                    <Col md="4">
                      <ListGroup>
                        { etl.job.stats.map((stat, index) => (
                          <ListGroupItem
                            className="list-group-item-action flex-column align-items-start py-4 px-4"
                            href=""
                            onClick={(e) => {
                              e.preventDefault();
                              setReport(stat.name);
                              setStatindex(stat.index);
                            }}
                            active={report == stat.name}
                            key={index}
                          >
                            <p className="text-sm mb-0">
                              <span className="text-info bold">{stat.display}</span>
                            </p>
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    </Col>
                    <Col md="8">
                      <Card className="bg-default shadow" style={{
                        height: 350
                      }}>
                        <CardHeader className="bg-transparent border-0">
                          <h3 className="text-white mb-0">Return Rewards</h3>
                        </CardHeader>
                        <div className="table-responsive" ref={listRef}>
                          <Table className="align-items-center table-dark table-flush">
                            <thead className="thead-dark">
                              <tr>
                                { etl.job.stats[statindex].headers.map((header, index) => (
                                  <th className="sort" data-sort={header.name} scope="col" key={index}>
                                    {header.display}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody className="list">
                              { etl.job.stats[statindex].data.map((row, index) => (
                                <tr key={index}>
                                  <td className={etl.job.stats[statindex].headers[0].name}>{row[etl.job.stats[statindex].headers[0].name]}</td>
                                  <td className={etl.job.stats[statindex].headers[1].name}>{row[etl.job.stats[statindex].headers[1].name]}</td>
                                  <td className={etl.job.stats[statindex].headers[2].name}>{row[etl.job.stats[statindex].headers[2].name]}</td>
                                  <td className={etl.job.stats[statindex].headers[3].name}>{row[etl.job.stats[statindex].headers[3].name]}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                        <CardFooter className="bg-default shadow">
                        </CardFooter>
                      </Card>
                      <Row>
                        <Col md="4">
                          <Button block color="primary" size="lg"
                            onClick={() => {
                              if(etl.job && etl.job.export.email && etl.job.export.email != "") {
                                window.location.href = etl.job.export.email;
                              }
                            }}
                          >
                              <span className="btn-inner--icon mr-2">
                                <i className="ni ni-cloud-download-95" />
                              </span>
                              <span className="btn-inner--text">Download Email List</span>
                          </Button>
                        </Col>
                        <Col md="4">
                          <Button block color="secondary" size="lg"
                            onClick={() => {
                              if(etl.job && etl.job.export.offers && etl.job.export.offers != "") {
                                window.location.href = etl.job.export.offers;
                              }
                            }}
                          >
                              <span className="btn-inner--icon mr-2">
                                <i className="ni ni-cloud-download-95" />
                              </span>
                              <span className="btn-inner--text">Download Offer Lists</span>
                          </Button>
                        </Col>
                        <Col md="4">
                          { etl.job.export.reports &&
                            <Button block color="default" size="lg"
                              onClick={() => {
                                if(etl.job && etl.job.export.reports && etl.job.export.reports != "") {
                                  window.location.href = etl.job.export.reports;
                                }
                              }}
                            >
                                <span className="btn-inner--icon mr-2">
                                  <i className="ni ni-cloud-download-95" />
                                </span>
                                <span className="btn-inner--text">Download Reports</span>
                            </Button>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                  <hr className="my-4" />
                  <Row>
                    <Col md="4">
                      <div style={{
                        height: '100%',
                        float: 'left'
                      }}>
                        <Button
                          color="primary"
                          href=""
                          size="xl"
                          onClick={() => {
                            dispatch({
                              type: UPDATE_PROCESS_STAGE,
                              payload: {
                                stage: 'upload'
                              }
                            });
                          }}
                        >
                          Upload Data
                        </Button>
                      </div>
                    </Col>
                    <Col className="text-right" md="8">

                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedReview = connect(state => ({
  profile: state.profile,
  etl: state.etl
}))(Review);

export default ConnectedReview;
