/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Account config
import config from "config";

import {
  SET_ETL_JOB,
  UPDATE_PROCESS_STAGE
} from 'store/actions';

function ProfileHeader({ profile, dispatch }) {
  // Get history
  const history = useHistory();

  return (
    <>
      <div
        className="header pb-6 d-flex align-items-center"
        style={{
          minHeight: "500px",
          backgroundImage: `url(${config.assets.img.brand.backgrounds.profile})`,
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <span className="mask bg-gradient-info opacity-1" />

        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="10">
              <h1 className="display-2 text-white">Hello{(profile.initialized && profile.user.details.firstName) ? ` ${profile.user.details.firstName}` : ``}</h1>
              <p className="text-white mt-0 mb-5">
                This is the Dynamic Loyalty data interface portal. You can easily upload and review daily processed offers.
              </p>
              <Row className="ml-2">
                <Button
                  color="secondary"
                  href=""
                  size="xl"
                  onClick={() => {
                    // Go to sent
                    history.push("/admin/offers/etl");
                  }}
                >
                  <span className="btn-inner--icon mr-2">
                    <i className="ni ni-bullet-list-67" />
                  </span>
                  <span className="btn-inner--text">View Processed Offers</span>
                </Button>
                <Button
                  className="btn-info"
                  color="default"
                  onClick={(e) => {
                    e.preventDefault();

                    // Set ETL job
                    dispatch({
                      type: SET_ETL_JOB,
                      payload: null
                    });

                    // Set process state
                    dispatch({
                      type: UPDATE_PROCESS_STAGE,
                      payload: {
                        stage: 'upload'
                      }
                    });

                    // Go to process
                    history.push("/admin/offers/etl/create");
                  }}
                >
                  <span className="btn-inner--icon mr-2">
                    <i className="ni ni-money-coins" />
                  </span>
                  <span className="btn-inner--text">Process New Offers</span>
                </Button>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

// Connect to store
const ConnectedProfileHeader = connect(state => ({
  profile: state.profile
}))(ProfileHeader);

export default ConnectedProfileHeader;
