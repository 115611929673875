/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// javascipt plugin for creating charts
import { Chart } from "chart.js";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Col,
  Modal
} from "reactstrap";

// Config
import config from "config";

// Firebase Firestore
import { firestore } from "./../../../../firebase";

// Accounting
import accounting from "accounting";

// core components
import ReportingHeader from "components/Headers/ReportingHeader.js";

// Platform API
import DynamicLoyalty from '@axon/dynamic-loyalty';

import {
  UPDATE_REPORTING_STAGE,
  UPDATE_REPORTING_SNAPSHOTS,
  SET_REPORTING_SNAPSHOT
} from 'store/actions';

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

function SnapshotsList({ profile, reporting, dispatch }) {
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");
  const firstListRef = React.useRef(null);
  const [combinedEmail, setCombinedEmail] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  // Delete ETL Job
  const [deleteJob, setDeleteJob] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);

  // ETL Job Notes
  const [notesJob, setNotesJob] = React.useState(false);
  const [notesModal, setNotesModal] = React.useState(false);

  // Get history
  const history = useHistory();

  // Watch etl object updates
  React.useEffect(() => {
    // Query firestore
    firestore.collection("reporting").orderBy("endDate", "desc").limit(20)
      .onSnapshot((querySnapshot) => {
        // Iterate and append to list
        let reportingSnapshots = [];
        querySnapshot.forEach((doc) => { reportingSnapshots.push(doc.data()); });

        // Update ETL
        dispatch({
          type: UPDATE_REPORTING_SNAPSHOTS,
          payload: reportingSnapshots
        });
      });
  }, []);

  // Chart interaction
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  // Select reportign snapshot
  const selectReportingSnapshot = (reportingSnapshot) => {
    // Set ETL job
    dispatch({
      type: SET_REPORTING_SNAPSHOT,
      payload: reportingSnapshot
    });

    // Set process state
    dispatch({
      type: UPDATE_REPORTING_STAGE,
      payload: {
        startDate: reportingSnapshot.startDate,
        endDate: reportingSnapshot.endDate,
        stage: (reportingSnapshot.status == "Complete") ? "review" : "upload"
      }
    });

    // Got to review page
    history.push("/admin/offers/snapshots/create");
  }

  return (
    <>
      <ReportingHeader
        stage="list"
        title="Reporting Snapshots"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="8">
                    <h3 className="mb-0">Processed Weekly Snapshots</h3>
                  </Col>
                  <Col className="text-right" xs="4">

                  </Col>
                </Row>
              </CardHeader>
              <div className="table-responsive" ref={firstListRef}>
                <Table className="align-items-center table-flush">
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="startDate" scope="col">
                        Start Date
                      </th>
                      <th className="sort" data-sort="endDate" scope="col">
                        End Date
                      </th>
                      <th scope="col">Team Member</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    { reporting.processed.map((reportingSnapshot, index) => (
                      <tr key={index}>
                        <th scope="row">
                          <span className="startDate mb-0 text-sm">
                            {reportingSnapshot.startDate}
                          </span>
                        </th>
                        <th scope="row">
                          <span className="endDate mb-0 text-sm">
                            {reportingSnapshot.endDate}
                          </span>
                        </th>
                        <td>
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <a
                                className="avatar rounded-circle"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src="https://storage.cloud.google.com/dls-cdn-dev/clients/caesars-southern-indiana/assets/caesars-avatar.jpeg"
                                />
                              </a>
                            </Col>
                            <div className="col">
                              <h4 className="mb-0">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                  {reportingSnapshot.user.name}
                                </a>
                              </h4>
                              <span className="text-success">●</span>{" "}
                              <small>{reportingSnapshot.user.email}</small>
                            </div>
                          </Row>
                        </td>
                        <td className="text-right">
                          {
                            {
                              'Complete': <UncontrolledDropdown group>
                                            <Button onClick={() => { selectReportingSnapshot(reportingSnapshot); }} color="primary" size="sm" type="button" style={{
                                              width: 100
                                            }}>
                                              View
                                            </Button>
                                            <DropdownToggle
                                              caret
                                              color="primary"
                                            />
                                            <DropdownMenu>
                                            </DropdownMenu>
                                          </UncontrolledDropdown>
                            }[reportingSnapshot.status]
                          }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedSnapshotsList = connect(state => ({
  profile: state.profile,
  reporting: state.reporting
}))(SnapshotsList);

export default ConnectedSnapshotsList;
