/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// javascipt plugin for creating charts
import { Chart } from "chart.js";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Col,
  Modal
} from "reactstrap";

// Config
import config from "config";

// Firebase Firestore
import { firestore } from "./../../../../firebase";

// Accounting
import accounting from "accounting";

// core components
import OffersHeader from "components/Headers/OffersHeader.js";

// Platform API
import DynamicLoyalty from '@axon/dynamic-loyalty';

import {
  UPDATE_PROCESSED_JOBS,
  SET_ETL_JOB,
  UPDATE_PROCESS_STAGE
} from 'store/actions';

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

function ProcessedList({ profile, etl, dispatch }) {
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");
  const firstListRef = React.useRef(null);
  const [combinedEmail, setCombinedEmail] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  // Delete ETL Job
  const [deleteJob, setDeleteJob] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);

  // ETL Job Notes
  const [notesJob, setNotesJob] = React.useState(false);
  const [notesModal, setNotesModal] = React.useState(false);

  // Get history
  const history = useHistory();

  // Watch etl object updates
  React.useEffect(() => {
    // Query firestore
    firestore.collection("etl").orderBy("date", "desc").limit(20)
      .onSnapshot((querySnapshot) => {
        // Iterate and append to list
        let etlJobs = [];
        querySnapshot.forEach((doc) => { etlJobs.push(doc.data()); });

        // Update ETL
        dispatch({
          type: UPDATE_PROCESSED_JOBS,
          payload: etlJobs
        });
      });
  }, []);

  // Chart interaction
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  // Select ETL job
  const selectETLJob = (etlJob) => {
    // Set ETL job
    dispatch({
      type: SET_ETL_JOB,
      payload: etlJob
    });

    // Set process state
    dispatch({
      type: UPDATE_PROCESS_STAGE,
      payload: {
        offerId: etlJob.offer.id,
        date: etlJob.date,
        stage: (etlJob.status == "Complete") ? "review" : "upload"
      }
    });

    // Got to review page
    history.push("/admin/offers/process");
  }

  // Re-process data
  const deleteETLJob = (etlJob) => {
    console.log("Deleting: ", etlJob.date);

    // Clear grants for day
    DynamicLoyalty.api.offers.clearRewards({
      date: etlJob.date
    }, () => {
      // Clear customer activity for day
      // ...
      DynamicLoyalty.api.offers.clearCustomerActivity({
        date: etlJob.date
      }, () => {
        // Delete ETL job
        let etlDocumentId = `caesars_southern_indiana_${etlJob.date.substr(0,4)}_${etlJob.date.substr(5,2)}_${etlJob.date.substr(8,2)}`;
        let etlDocRef = firestore.collection("etl").doc(etlDocumentId);
        etlDocRef.delete().then(() => {
          console.log(" --> Done!");

          // Close modal
          setDeleteModal(false);
        });
      });
    });
  }

  // Combine emails
  const combineEmails = () => {
    // Get selected etl jobs
    let selectedETLJobs = etl.processed.filter((_, index) => _.selected);

    // Create object to send
    let etlJobsDocuments = [];
    for(var i = 0; i < selectedETLJobs.length; i++) {
      // Construct document ID
      etlJobsDocuments.push(`caesars_southern_indiana_${selectedETLJobs[i].date.substr(0,4)}_${selectedETLJobs[i].date.substr(5,2)}_${selectedETLJobs[i].date.substr(8,2)}`);
    }

    // Query combination service
    setLoading(true);
    axios.post(`${config.etl.api.combine.url}`, etlJobsDocuments).then(results => {
      // Open URL for results
      if(results.data && results.data.email && results.data.email !== "") {
        // Open URL
        window.open(results.data.email.url, '_self');

        // Mark complete
        setLoading(false);
      }
    });
  }

  return (
    <>
      <OffersHeader
        stage="list"
        title="Processed Offers"
        description="These are all of the previously or actively processing daily offers. Browse to see results or start a new day by clicking below."
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="8">
                    <h3 className="mb-0">Processed Daily Offers</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    { combinedEmail &&
                      <Button
                        onClick={combineEmails}
                        color={(loading) ? "secondary" : "primary"}
                        size="md"
                        type="button"
                        style={{
                          width: 250
                        }}
                      >
                        {
                          loading ?
                            <span className="btn-inner--text">Generating...</span>
                            :
                            <>
                              <span className="btn-inner--icon mr-2">
                                <i className="ni ni-cloud-download-95" />
                              </span>
                              <span className="btn-inner--text">Download Email List</span>
                            </>
                        }
                      </Button>
                    }
                  </Col>
                </Row>
              </CardHeader>
              <div className="table-responsive" ref={firstListRef}>
                <Table className="align-items-center table-flush">
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="date" scope="col">
                        Play Date
                      </th>
                      <th className="sort" data-sort="date" scope="col">
                        Pull Date
                      </th>
                      <th className="sort" data-sort="offer" scope="col">
                        Offer ID
                      </th>
                      <th className="sort" data-sort="recipients" scope="col">
                        Qualified Guests
                      </th>
                      <th className="sort" data-sort="exposure" scope="col">
                        Total Exposure
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Status
                      </th>
                      <th scope="col">Team Member</th>
                      <th scope="col" />
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    { etl.processed.map((etlJob, index) => (
                      <tr key={index}>
                        <th scope="row">
                          <span className="date mb-0 text-sm">
                            {etlJob.date}
                          </span>
                        </th>
                        <th scope="row">
                          <span className="date mb-0 text-sm">
                            {(etlJob.pullDate) ? etlJob.pullDate : ''}
                          </span>
                        </th>
                        <td className="offer">{etlJob.offer.id}</td>
                        <td className="recipients">{etlJob.summary.total_recipients}</td>
                        <td className="exposure">{accounting.formatMoney(etlJob.summary.total_exposure, { symbol: "USD",  format: "%v %s" })}</td>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-warning" />
                            <span className="status">{etlJob.status}</span>
                          </Badge>
                        </td>
                        <td>
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <a
                                className="avatar rounded-circle"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src="https://storage.cloud.google.com/dls-cdn-dev/clients/caesars-southern-indiana/assets/caesars-avatar.jpeg"
                                />
                              </a>
                            </Col>
                            <div className="col">
                              <h4 className="mb-0">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                  {etlJob.user.name}
                                </a>
                              </h4>
                              <span className="text-success">●</span>{" "}
                              <small>{etlJob.user.email}</small>
                            </div>
                          </Row>
                        </td>
                        <td scope="row">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              onChange={(evt) => {

                                // Market ETL Job
                                etlJob.selected = evt.target.checked;
                                let selectedETLJobs = etl.processed.filter((_, index) => _.selected);
                                console.log("Total Selected: ", selectedETLJobs.length);

                                // Show / hide email
                                if(selectedETLJobs.length > 1) {
                                  setCombinedEmail(true);
                                } else {
                                  setCombinedEmail(false);
                                }
                              }}
                            />
                          </div>
                        </td>
                        <td className="text-right">
                          {
                            {
                              'Complete': <UncontrolledDropdown group>
                                            <Button onClick={() => { selectETLJob(etlJob); }} color="primary" size="sm" type="button" style={{
                                              width: 100
                                            }}>
                                              View
                                            </Button>
                                            <DropdownToggle
                                              caret
                                              color="primary"
                                            />
                                            <DropdownMenu>
                                              <DropdownItem onClick={() => {
                                                setDeleteJob(etlJob);
                                                setDeleteModal(true);
                                              }}>
                                                Delete
                                              </DropdownItem>
                                              <DropdownItem onClick={() => {
                                                setNotesJob(etlJob);
                                                setNotesModal(true);
                                              }}>
                                                Notes
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </UncontrolledDropdown>,
                              'Processing': <UncontrolledDropdown group>
                                              <Button color="secondary" size="sm" type="button" style={{
                                                width: 100
                                              }}>
                                                <Progress
                                                  max="100"
                                                  value={ 100.0 * (etlJob.feeds.rated_day.processed / etlJob.feeds.rated_day.total) }
                                                  color="gradient-primary"
                                                />
                                              </Button>
                                              <DropdownToggle
                                                caret
                                                color="secondary"
                                              />
                                              <DropdownMenu>
                                                <DropdownItem onClick={() => {
                                                  setDeleteJob(etlJob);
                                                  setDeleteModal(true);
                                                }}>
                                                </DropdownItem>
                                              </DropdownMenu>
                                          </UncontrolledDropdown>,
                              'Exporting': <UncontrolledDropdown group>
                                              <Button onClick={() => { selectETLJob(etlJob); }} color="secondary" size="sm" type="button" style={{
                                                width: 100
                                              }}>
                                                View
                                              </Button>
                                              <DropdownToggle
                                                caret
                                                color="secondary"
                                              />
                                              <DropdownMenu>
                                                <DropdownItem onClick={() => {
                                                  setDeleteJob(etlJob);
                                                  setDeleteModal(true);
                                                }}>
                                                </DropdownItem>
                                              </DropdownMenu>
                                          </UncontrolledDropdown>,
                              'Uploading': <UncontrolledDropdown group>
                                              <Button onClick={() => { selectETLJob(etlJob); }} color="secondary" size="sm" type="button" style={{
                                                width: 100
                                              }}>
                                                View
                                              </Button>
                                              <DropdownToggle
                                                caret
                                                color="secondary"
                                              />
                                              <DropdownMenu>
                                                <DropdownItem onClick={() => {
                                                  setDeleteJob(etlJob);
                                                  setDeleteModal(true);
                                                }}>
                                                  Delete
                                                </DropdownItem>
                                              </DropdownMenu>
                                          </UncontrolledDropdown>
                            }[etlJob.status]
                          }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered"
        isOpen={deleteModal}
        toggle={() => setDeleteModal(false)}
        size="lg"
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Confirm Deletion
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setDeleteModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="delete-confirm"
                    >
                      Please Type DELETE to Proceed
                    </label>
                    <Input
                      id="delete-confirm"
                      placeholder=""
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            href=""
            onClick={() => {
              // Update contact
              console.log("Deleting ETL Job");

              // Get value of form
              const deleteConfirmation = document.getElementById('delete-confirm').value;

              // Verify if delete exists
              if(deleteConfirmation === "DELETE") {
                deleteETLJob(deleteJob);
              }
            }}
            size="xl"
          >
            Confirm Deletion
          </Button>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={notesModal}
        toggle={() => setNotesModal(false)}
        size="lg"
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Edit Notes for Processed Day
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setNotesModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="etl-job-notes"
                    >
                      Enter Notes for Specific Processed Day
                    </label>
                    <Input
                      id="etl-job-notes"
                      placeholder="Enter notes for this day..."
                      defaultValue={notesJob.notes}
                      rows="7"
                      type="textarea"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            href=""
            onClick={() => {
              // Update contact
              const documentId = `caesars_southern_indiana_${notesJob.date.substr(0,4)}_${notesJob.date.substr(5,2)}_${notesJob.date.substr(8,2)}`;
              console.log("Saving ETL Job Notes: ", documentId);

              // Save notes to job
              let updatedNotes = document.getElementById('etl-job-notes').value;
              console.log(updatedNotes);

              // Update ETL Job
              const etlJobRef = firestore.collection("etl").doc(documentId);
              etlJobRef.set({ notes: updatedNotes}, { merge: true });

              // Close modal
              setNotesModal(false);
            }}
            size="xl"
          >
            Save Note
          </Button>
        </div>
      </Modal>
    </>
  );
}

// Connect to store
const ConnectedProcessedList = connect(state => ({
  profile: state.profile,
  etl: state.etl
}))(ProcessedList);

export default ConnectedProcessedList;
