/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// javascipt plugin for creating charts
import { Chart } from "chart.js";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// Accounting
import accounting from 'accounting';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ProfileHeader from "components/Headers/ProfileHeader.js";

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

function Home({ profile, etl, dispatch }) {
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  // Update KPI's


  return (
    <>
      <ProfileHeader />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="4">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Total Qualified Guests
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">
                      {accounting.formatNumber(etl.kpi.totalRecipients)}
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                      <i className="ni ni-credit-card" />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-sm">
                  <span className="text-nowrap">Prior 30 Days</span>
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Total Exposure
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">
                      ${accounting.formatMoney(etl.kpi.totalExposure, { symbol: "USD",  format: "%v" })}
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                      <i className="ni ni-money-coins" />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-sm">
                  <span className="text-nowrap">Prior 30 Days</span>
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Exposure per Guest
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">
                      ${accounting.formatMoney(etl.kpi.exposurePerGuest, { symbol: "USD",  format: "%v" })}
                    </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                      <i className="ni ni-circle-08" />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-sm">
                  <span className="text-nowrap">Prior 30 Days</span>
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="8">
            <Card className="bg-default">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-light text-uppercase ls-1 mb-1">
                      Return Rewards
                    </h6>
                    <h5 className="h3 text-white mb-0">Daily Exposure</h5>
                  </div>
                  <div className="col">

                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  { etl.chart &&
                    <Line
                      data={etl.chart}
                      options={chartExample1.options}
                      id="chart-sales-dark"
                      className="chart-canvas"
                    />
                  }
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0">Recently Processed Days</h5>
              </CardHeader>

              <CardBody>
                <ListGroup className="list my--3" flush>
                  { etl.processed.slice(0, 5).map((etlJob, index) => (
                    <ListGroupItem className="px-0" key={index}>
                      <Row className="align-items-center">
                        <Col md="8">
                          <h4 className="mb-0">
                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                              {etlJob.feeds.rated_day.total} Rated Days - {etlJob.date}
                            </a>
                          </h4>
                          <span className="text-success">●</span>{" "}
                          <small>Processed</small>
                        </Col>
                        <Col md="4">
                          <Button color="primary" size="md" type="button" block>
                            View
                          </Button>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedHome = connect(state => ({
  profile: state.profile,
  etl: state.etl
}))(Home);

export default ConnectedHome;
