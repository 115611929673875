/*
    Offers API
 */

// Imports (external)
import { request, gql } from 'graphql-request';
import _ from 'lodash';

// Import Configuration
import config from '../config';

// Offers
const Offers = {
  // Get offer template (for given ID)
  templates: function(params, callback) {
    Promise.all(params.types.map(template =>
      fetch(`${config.offers.executor.url}/${template.domain}/${template.type}`)
        .then(resp => resp.json())
    )).then(data => {
      // Format offers
      const offerTypes = _.map(data, (d) => { return d.offer; });

      if(callback) {
        callback(offerTypes);
      }
    });
  },

  // Create
  create: function(params, callback) {
    // Request storefronts from API
    const query = gql`
      mutation CreateOffer($offer: OfferCreateInput!) {
        createOffer(offer: $offer) {
          id
          display
          startDate
          endDate
        }
      }
    `

    // Execute query
    request(config.api.url, query, {
      offer: params
    }).then((data) => {
      // Parse data
      const newOffer = (data.createOffer) ? data.createOffer : null;

      // Return data
      callback(newOffer);
    }).catch((error) => {
      console.error(error);

      // Return empty array
      callback(null);
    });
  },

  // Assign
  assign: function(params, callback) {
    // Request storefronts from API
    const query = gql`
      mutation AssignOffer($offer: AssignedOfferCreateInput!) {
        assignOffer(offer: $offer) {
          id
          status
          task
        }
      }
    `

    // Execute query
    request(config.api.url, query, {
      offer: params
    }).then((data) => {
      // Parse data
      const newOffer = (data.createOffer) ? data.createOffer : null;

      // Return data
      callback(newOffer);
    }).catch((error) => {
      console.error(error);

      // Return empty array
      callback(null);
    });
  },

  // Find (by Cohort)
  find: function(params, callback) {
    // Request storefronts from API
    const query = gql`
      query FindOffers($cohortId: String) {
        findOffers(cohortId: $cohortId) {
          id
          campaignId
          offerId
          customerId
          name
          inControlGroup
          status
        }
      }
    `

    // Execute query
    request(config.api.url, query, {
      cohortId: params.cohortId
    }).then((data) => {
      // Parse data
      const offers = (data.findOffers && data.findOffers.length > 0) ? data.findOffers : [];

      // Return data
      callback(offers);
    }).catch((error) => {
      console.error(error);

      // Return empty array
      callback([]);
    });
  },

  viewAssigned: function(params, callback) {
    // Request storefronts from API
    const query = gql`
      query ViewAssignedOffers($offerId: Int, $skip: Int, $take: Int) {
        viewAssignedOffers(offerId: $offerId, skip: $skip, take: $take) {
          status
          task
          published
          customer {
            id
            name
            email
            cohortId
          }
        }
      }
    `

    // Execute query
    request(config.api.url, query, {
      offerId: params.offerId,
      skip: 0,
      take: 10
    }).then((data) => {
      // Parse data
      const assignedOffers = (data.viewAssignedOffers && data.viewAssignedOffers.length > 0) ? data.viewAssignedOffers : [];

      // Return data
      callback(assignedOffers);
    }).catch((error) => {
      console.error(error);

      // Return empty array
      callback([]);
    });
  },

  // Clear Customer Activity
  clearCustomerActivity: function(params, callback) {
    // Request storefronts from API
    const query = gql`
      mutation ClearCustomerActivity($date: String) {
        clearCustomerActivity(date: $date) {
          command
          total
        }
      }
    `

    // Execute query
    request(config.api.url, query, {
      date: params.date
    }).then((data) => {
      // Return data
      callback(null);
    }).catch((error) => {
      console.error(error);

      // Return empty array
      callback(null);
    });
  },

  // Clear Rewards
  clearRewards: function(params, callback) {
    // Request storefronts from API
    const query = gql`
      mutation ClearRewards($date: String) {
        clearRewards(date: $date) {
          command
          total
        }
      }
    `

    // Execute query
    request(config.api.url, query, {
      date: params.date
    }).then((data) => {
      // Return data
      callback(null);
    }).catch((error) => {
      console.error(error);

      // Return empty array
      callback(null);
    });
  },
}

// Export module
export default Offers;
