// Accounting
import accounting from 'accounting';

// action - state management
import * as actionTypes from '../actions';

const initialState = {
    initialized: false,
    job: null,
    processed: [],
    process: {
      offerId: null,
      date: null,
      stage: 'upload'
    },
    kpi: {
      totalRecipients: 0,
      totalExposure: 0,
      exposurePerGuest: 0
    },
    chart: null
};

// ===========================|| PRODUCTS REDUCER ||=========================== //

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INITIALIZE_ETL: {
          console.log("Initializing ETL ...", action.payload);

          // Calculate KPI's
          let totalRecipients = 0;
          let totalExposure = 0;
          let ratedDays = [];
          let dailyExposure = [];
          for(var i = 0; i < action.payload.length; i++) {
            // Increment
            totalRecipients += action.payload[i].summary.total_recipients;
            totalExposure += action.payload[i].summary.total_exposure;

            // Update chart
            ratedDays.push(action.payload[i].date);
            dailyExposure.push(action.payload[i].summary.total_exposure);
          }

          // Create chart function
          const chart = (canvas) => {
            return {
              labels: ratedDays.reverse(),
              datasets: [
                {
                  label: "Total Exposure",
                  data: dailyExposure.reverse()
                },
              ],
            };
          }

          // Update state
          return {
              ...state,
              initialized: true,
              processed: action.payload,
              kpi: {
                totalRecipients: totalRecipients,
                totalExposure: totalExposure,
                exposurePerGuest: 1.0 * totalExposure / totalRecipients
              },
              chart: chart
          };
        }
        case actionTypes.SET_ETL_JOB: {
          console.log("Setting ETL job ...", action.payload);

          // Update state
          return {
              ...state,
              job: action.payload
          };
        }
        case actionTypes.UPDATE_PROCESSED_JOBS: {
          console.log("Updated processed job ...", action.payload);

          // Update state
          return {
              ...state,
              processed: action.payload
          };
        }
        default:
            return state;
    }
};

export default profileReducer;
