/*
 *  Dynamic Loyalty SDK
 *  ---------------
 *  Powered by Axon
 */

// Import API endpoints
import Offers from './offers';

// Export object
const DynamicLoyalty = {
  namespace: "@axon/dynamic-loyalty",

  api: {
    offers: Offers
  }
};

// Exports
export default DynamicLoyalty;
