// action - state management
import * as actionTypes from '../actions';

const initialState = {
    initialized: false,
    snapshot: null,
    processed: [],
    process: {
      startDate: null,
      endDate: null,
      stage: 'upload'
    }
};

// ===========================|| PRODUCTS REDUCER ||=========================== //

const reportingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INITIALIZE_REPORTING: {
          console.log("Initializing Snapshots ...", action.payload);

          // Update state
          return {
              ...state,
              initialized: true,
              processed: action.payload
          };
        }
        case actionTypes.SET_REPORTING_SNAPSHOT: {
          console.log("Setting reportin snapshot ...", action.payload);

          // Update state
          return {
              ...state,
              snapshot: action.payload
          };
        }
        case actionTypes.UPDATE_REPORTING_SNAPSHOTS: {
          console.log("Updated reporting snapshots ...", action.payload);

          // Update state
          return {
              ...state,
              processed: action.payload
          };
        }
        default:
            return state;
    }
};

export default reportingReducer;
