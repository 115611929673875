// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALIZE = 'ACCOUNT_INITIALIZE';

// action - profile
export const INITIALIZE_PROFILE = '@profile/INITIALIZE_PROFILE';
export const CLEAR_PROFILE = '@profile/CLEAR_PROFILE';
export const UPDATE_PROFILE_USER = '@profile/UPDATE_PROFILE_USER';
export const UPDATE_PROCESS_STAGE = '@profile/UPDATE_PROCESS_STAGE';

// action - etl
export const INITIALIZE_ETL = '@etl/INITIALIZE_ETL';
export const SET_ETL_JOB = '@etl/SET_ETL_JOB';
export const UPDATE_PROCESSED_JOBS = '@etl/UPDATE_PROCESSED_JOBS';

// action - reporting
export const INITIALIZE_REPORTING = '@reporting/INITIALIZE_REPORTING';
export const UPDATE_REPORTING_STAGE = '@reporting/UPDATE_REPORTING_STAGE';
export const SET_REPORTING_SNAPSHOT = '@reporting/SET_REPORTING_SNAPSHOT';
export const UPDATE_REPORTING_SNAPSHOTS = '@reporting/UPDATE_REPORTING_SNAPSHOTS';
