// action - state management
import * as actionTypes from '../actions';

const initialState = {
    initialized: false,
    user: {
      uid: null,
      name: null
    },
    process: {
      offerId: null,
      date: null,
      stage: 'upload'
    }
};

// ===========================|| PRODUCTS REDUCER ||=========================== //

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INITIALIZE_PROFILE: {
          console.log("Initializing profile...", action.payload);

          // Update state
          return {
              ...state,
              initialized: true,
              user: (action.payload) ? action.payload : state.user
          };
        }
        case actionTypes.UPDATE_PROCESS_STAGE: {
          console.log("Changing process stage...", action.payload);

          // Update state
          return {
              ...state,
              process: action.payload
          };
        }
        default:
            return state;
    }
};

export default profileReducer;
