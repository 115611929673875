import { combineReducers } from 'redux';

// Dynamic Loyalty reducers
import profileReducer from './profile';
import etlReducer from './etl';
import reportingReducer from './reporting';

// ===========================|| COMBINE REDUCER ||=========================== //

const reducer = combineReducers({
    profile: profileReducer,
    etl: etlReducer,
    reporting: reportingReducer
});

export default reducer;
